<template>
  <div class="page" id="create-agent">
    <h2 class="mt-2 mb-3">{{ $t('agents.create-agent.title') }}</h2>
    <div class="form">
      <div class="row w-100">
        <div class="col-12 mb-4">
          <field
            :label="$t('agents.create-agent.form.name.label')"
            v-model="model.name"
            :placeholder="$t('agents.create-agent.form.name.placeholder')"
            :error="errors.name"
          />
        </div>
        <div class="col-12 mb-4">
          <field
            :label="$t('agents.create-agent.form.email.label')"
            v-model="model.email"
            :placeholder="$t('agents.create-agent.form.email.placeholder')"
            :error="errors.email"
          />
        </div>
        <div class="col-12 mb-4">
          <field
            type="password"
            :label="$t('agents.create-agent.form.password.label')"
            v-model="model.password"
            :placeholder="$t('agents.create-agent.form.password.placeholder')"
            :error="errors.password"
          />
        </div>
        <div class="col-12 mb-4">
          <field
            :label="$t('agents.create-agent.form.assigned-extension.label')"
            :error="errors.assignedExtension"
          >
            <b-form-select
              v-model="model.assignedExtension"
              :options="extensions"
            >
              <template v-slot:first>
                <b-form-select-option disabled value="">
                  {{
                    $t(
                      'agents.create-agent.form.assigned-extension.placeholder'
                    )
                  }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </field>
        </div>
        <div class="col-12 mb-4">
          <b-form-checkbox v-model="model.canListenToRecords">
            Can Listen To Records
          </b-form-checkbox>
        </div>
        <div class="col-12 mb-4">
          <button
            class="btn btn-primary btn-sm"
            @click="onCreate"
            :disabled="loading"
          >
            <half-circle-spinner v-if="loading" :size="20" color="#fff" />
            <span v-else>
              {{ $t('agents.create-agent.form.create') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string } from 'yup'
import Field from '../../../elements/Field.vue'
import agentService from '../../../services/agent.service'
import ExtensionService from '../../../services/extension.service'
import { yupToKV } from '../../../utils/yup'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  name: 'create-agent',
  components: { Field, HalfCircleSpinner },
  data() {
    return {
      extensions: [],
      model: {
        name: '',
        email: '',
        password: '',
        assignedExtension: '',
        canListenToRecords: true
      },
      errors: {},
      loading: false
    }
  },
  methods: {
    async getExtensionsNumbers() {
      const res = await ExtensionService.getAllExtensionsNumbers()
      this.extensions = res.map(ext => ({ value: ext.id, text: ext.ext }))
    },
    async onCreate() {
      this.loading = true
      await this.createSchema
        .validate(this.model, { abortEarly: false })
        .catch(err => {
          this.errors = yupToKV(err)
        })

      if (this.createSchema.isValidSync(this.model)) {
        this.errors = {}
        try {
          await agentService.createAgent({
            ...this.model,
            assignedExtension: +this.model.assignedExtension
          })
          this.toast(this.$t('agents.create-agent.toasts.agent-created'))
          this.$router.push(`/agents`)
        } catch (error) {
          this.toast(error.response.data.message, { type: 'error' })
        } finally {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getExtensionsNumbers()
  },
  computed: {
    createSchema() {
      return object().shape({
        name: string()
          .trim()
          .required(this.$t('agents.create-agent.errors.required.name')),
        email: string()
          .email(this.$t('agents.create-agent.errors.invalid.email'))
          .required(this.$t('agents.create-agent.errors.required.email')),
        password: string()
          .min(8, this.$t('agents.create-agent.errors.length.password'))
          .required(this.$t('agents.create-agent.errors.required.password')),
        assignedExtension: string()
          .matches(
            /[0-9]/,
            this.$t('agents.create-agent.errors.invalid.assigned-extension')
          )
          .min(
            1,
            this.$t('agents.create-agent.errors.length.assigned-extension')
          )
          .required(
            this.$t('agents.create-agent.errors.required.assigned-extension')
          )
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
